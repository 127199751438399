<template>
  <div>
    <Dropdown
      ref="dropdown"
      label="fullName"
      :options="operators"
      @input="onInput"
      :value="value"
      :placeholder="$t('components.EditOperator.placeholder')"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import DetailUpdateMixin from "@/mixins/DetailUpdateMixin";
import Dropdown from '@/components/activity/edit/Dropdown.vue';

export default {
  mixins: [DetailUpdateMixin],
  components: {
    Dropdown
  },
  computed: {
    ...mapGetters({
      operators: "operators/operators"
    }),
    operatorsOptions() {
      return this.operators || []
    },
    value() {
      // get the value of current edited entry or current entry
      return this.operatorsOptions.find(c => c.id == (this.currentEditedEntry.operator || this.currentEntry.operator))
    },
  },
  async mounted() {
    await this.$store.dispatch("operators/fetchOperators");
    this.$refs["dropdown"].setValue(this.value)
  },
  methods: {
    onInput(operator) {
      this.$store.dispatch(this.storeEditAction, {
        operator: operator?.id || null,
      });
    },
  }
};
</script>

<style lang="scss" scoped>
</style>